import styles from './mainTitle.module.css'

const MainTitle = ({title, white=false}) => {
    return(

        <h2 className={white?`${styles.mainTitle} ${styles.white}`: styles.mainTitle}>
        {title}
        </h2>
        )
}

export default MainTitle