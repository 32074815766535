import Header from "../components/header"
import styles from './layout.module.css'
import ScreenContainer from '../components/screenContainer'
import MainTitle from "../components/mainTitle"
import MiddleText from "../components/middleText"
import CtaBtn from "../components/callToActionBtn"
import Panel2 from '../../imgs/panel2.png'
import logo from '../../imgs/logo.png'
import IconText from "../components/iconText"
import TextContainer from "../components/textContainer"
import Card from "../components/card"
import Links from "../components/links"
import Faq from "../components/faq"
import Footer from "../../sections/footer"
import FabWhats from "../components/fabWhats"
const cards = [
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
    {image: '/', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', name: 'Lorem Ipsum' },
]
const goToWpp = () => {
   window.location.href = 'https://wa.me/61998583348'
}
const Irrigacao = () => {
    return (
        <div className={styles.container}>
            <ScreenContainer id='main' direction={'col'}>
                <Header/>
                <FabWhats/>
                <div className={styles.textContainer}>
                    <MainTitle title='Energia Solar para Agronegócio! Até 90% de Economia.'/>
                    <MiddleText text='Deixe o sol pagar sua conta de energia com a DiCarvalho Solar!' />
                    <CtaBtn text='Quero fazer um orçamento' onclick={goToWpp}/>
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'row'}>
                <div id='sobre' className={styles.textContainer}>
                    <MainTitle title='Sobre a DiCarvalho'/>
                    <MiddleText text={`A DiCarvalho Solar foi fundada em julho de 2012 com o objetivo de oferecer serviços especializados em instalação e manutenção elétrica, eletroeletrônica, telefonia e rede. Desde então, a empresa tem se destacado pela sua dedicação à qualidade, confiança e honestidade em todos os projetos. Em 2018, a DiCarvalho deu um passo além e passou a oferecer serviços de projetar, instalar e homologar sistemas fotovoltaicos, impulsionando sua presença no mercado.

Com um crescimento contínuo, a DiCarvalho se consolidou como uma referência no setor de instalação de energia solar. Sua equipe qualificada e experiente está preparada para atender às demandas dos clientes, oferecendo soluções personalizadas para cada projeto. Ao escolher a DiCarvalho Solar, você está optando por uma empresa comprometida em proporcionar um serviço excepcional, aliando conhecimento técnico e eficiência.`} />
                </div>
                <div className={styles.textContainer}>
                    <img className={styles.logo} src={logo}/>
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'row'}>
                <div className={styles.textContainer}>
                    <img src={Panel2}/>
                </div>
                <div className={styles.textContainer}>
                    <MainTitle title='Deixe o sol pagar sua conta de energia com a DiCarvalho Solar!'/>
                    <MiddleText text={`Através dos nossos sistemas fotovoltaicos eficientes, você pode economizar até 95% na sua conta de energia. Além disso, ao adotar a energia solar, você estará contribuindo para o meio ambiente, reduzindo significativamente o consumo de eletricidade e garantindo uma fonte de energia limpa e sustentável para sua residência ou negócio.`} />
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'col'}>
                <TextContainer >
                    <MainTitle title='Nossa equipe cuidará de todo o processo de transição para a energia solar'/>
                    <MiddleText text='Desde o projeto inicial até a instalação e homologação do sistema. Trabalhamos apenas com produtos de alta qualidade, assegurando o melhor desempenho e durabilidade para o seu investimento.' />
                </TextContainer>
                <div className={styles.iconsContainer}>
                    <IconText label='Até 95% de economia na sua conta de luz' icon='savings'/>
                    <IconText label={`Garantia de até 25 anos 
                    (durabilidade média do kit)`} icon='health_and_safety'/>
                    <IconText label='5 Anos Retorno garantido (média do retorno do investimento)' icon='schedule'/>
                    <IconText label='+ Rapidez Produtos diretamente da importadora' icon='bolt'/>
                    <IconText label='Energia limpa Sustentabilidade e 10x menos emissão de CO2' icon='recycling'/>
                </div>
            </ScreenContainer>
            <ScreenContainer direction={'col'}>
                <IconText label='' icon='sunny'/>
                <TextContainer color>
                    <MainTitle white title='Di Carvalho Energia Solar'/>
                    <MiddleText text='Deixe o sol pagar sua conta de energia com a DiCarvalho Solar!' />
                    <CtaBtn white text='Quero fazer um orçamento' onclick={goToWpp}/>
                </TextContainer>
            </ScreenContainer>
            {/* <ScreenContainer direction={'col'}>
                <MainTitle title='Depoimentos'/>
                <br/>
                <div className={styles.iconsContainer}>
                    {cards?.map((card) => (
                        <Card {...card}/>
                    ))}
                </div>
            </ScreenContainer> */}
            <ScreenContainer direction={'row'}>
                <div className={styles.textContainer}>
                    <img className={styles.logo} src={logo}/>
                </div>
                <div id="serviços" className={styles.textContainer}>
                    <MainTitle title='Faça parte da revolução energética'/>
                    <MiddleText text='Entre em contato conosco hoje mesmo para descobrir como a DiCarvalho Solar pode ajudá-lo a economizar e desfrutar dos benefícios da energia solar de forma inteligente e sustentável. O sol está pronto para trabalhar a seu favor e nós estamos prontos para fazer isso acontecer!' />
                    <MiddleText text={`Atendemos: Residencial, comercial e agronegócio`} />
                <Links/>
                </div>
            </ScreenContainer>
            {/* <ScreenContainer id='faq' direction={'col'}>
                <MainTitle title='Perguntas Frequentes'/>
                <Faq/>
            </ScreenContainer> */}
            <Footer id='contato' isLandingPage/>
        </div>
    )
}

export default Irrigacao