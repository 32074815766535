import { useState } from 'react'
import doll from '../../imgs/doll.png'
import './styles.css'

const Form = () => {
        return(
        <div id='form'>
        <img className='img' src={doll} />
            <form action="https://formsubmit.co/dicarvalhoenergiasolar@gmail.com" className='form' method="POST">
                <h1 className='formTitle'>Solicite um orçamento</h1>
                <input name='Nome' className='input'  placeholder='Nome' type='name' required/>
                <input name='Telefone' className='input' placeholder='Telefone para contato' type='tel' required/>
                <input className='submit' type='submit' value='Enviar'/>
            </form>
        </div>
    )
}

export default Form