import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom'
import App from './App'
import Granja from './landingpages/granja'
import Acougue from './landingpages/acougue'
import Irrigacao from './landingpages/irrigacao'
import Residencial from './landingpages/residencial'


const AppRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route exact path='/*' element={<App />} />
				<Route exact path='/energia-solar-para-granja' element={<Granja />}/>
				<Route exact path='/energia-solar-para-acougue' element={<Acougue />}/>
				<Route exact path='/energia-solar-para-pivo-de-irrigacao' element={<Irrigacao />}/>
				<Route exact path='/energia-solar-residencial-em-brasilia-e-goias' element={<Residencial />}/>
			</Routes>
		</Router>
	)
}

export default AppRoutes
