import './styles.css'
import logo from '../../imgs/logo.png'
import insta from '../../imgs/instagram.png'

const Footer = ({id='',isLandingPage = false}) => {
    return(
        <>
        <div id='footer'>
            <div id={id} className='address section'>
                <h1 className='sectionTitle'>Endereço</h1>
                <span className='text'>Quadra 4M Conjunto C Lote 32 Arapoanga</span>
                <span className='text'>Planaltina-DF</span>
                <span className='text'>CEP 73369070</span>
                <span  className='text'>CNPJ: 16.537.457/0001-70</span>
            </div>
            <div className='contact section'>
                <h1 className='sectionTitle'>Contato</h1>
                <a className='link' target='_blank' href='https://whatsa.me/5561998583348/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
                    <span  className='text'>Whatsapp: 61 9 9858-3348</span>
                </a>
                <a className='link' target='_blank' href='mailto:dicarvalhoenergiasolar@gmail.com' >
                    <span  className='text'>Email: dicarvalhoenergiasolar@gmail.com</span>
                </a>
               {!isLandingPage && (
               <a className='link' target='_blank' href='https://www.instagram.com/dicarvalhoenergiasolar' >
                    <span className='text'>Instagram: <img src={insta} className='instaLogo' /></span>
                </a>
               )} 
            </div>
            <div>
                <img className='logo' src={logo} />
            </div>
        </div>
        <div className='lastLine'>® diCarvalho Energia Solar - 2023</div>
        </>
    )
}

export default Footer