import './styles.css'
import Carousel from 'nuka-carousel';
import f01 from './images/01.jpeg'
import f05 from './images/05.jpeg'
import f06 from './images/06.jpeg'
import f07 from './images/07.jpeg'
import f08 from './images/08.jpeg'
import f09 from './images/09.jpeg'
import f12 from './images/12.jpeg'
import f13 from './images/13.jpeg'
import f14 from './images/14.jpeg'
import f15 from './images/15.jpeg'
import f16 from './images/16.jpeg'
import f17 from './images/17.jpeg'
import f18 from './images/18.jpeg'
import f19 from './images/19.jpeg'

const Galery = () => {
    const galery = [f01,f05,f06,f07,f08,f09,f12,f13,f14,f15,f16,f17,f18,f19,]
    return(
        <div id='galery'>
            <h1 className='title'>Galeria</h1>
            <div className="imgsContainer">

              <Carousel cellAlign='center' animation='fade' autoplay={true} autoplayInterval={3000} wrapAround={true} className='galeryCarousel' defaultControlsConfig={{
                  nextButtonText: <span className="rightArrow">{'>'}</span>,
                  prevButtonText: <span className="leftArrow">{'<'}</span>,
                }}>
                {galery.map((image) =><img className="galeryImage" src={image}/> )}  
            </Carousel>
            </div>
        </div>
    )
}

export default Galery