import { AiOutlineThunderbolt } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import { RiWaterFlashFill } from "react-icons/ri";
import './styles.css'

const txt = `
Transforme sua casa em uma fonte de energia limpa e renovável com nossos serviços! 

Você pode desfrutar de uma fonte de energia confiável e eficiente, sem precisar se 
preocupar com altas contas de energia.

Além disso, oferecemos: 

- Instalações elétricas em geral e projetos fotovoltaicos personalizados para atender 
às suas necessidades específicas;

- Instalação de bombas de poço artesiano, incluindo sistemas solares. 

Entre em contato conosco agora mesmo que iremos te atender.
`

const Copy = () => {

    const cards =[
        {txt: `Transforme sua casa em uma fonte de energia limpa e renovável com nossos serviços! 
        Entre em contato conosco agora mesmo e faça um orçamento sem compromisso!`,
        icon: (<AiOutlineThunderbolt size={40} color='var(--azul)'/>)
    },
        {txt: `
        Você pode desfrutar de uma fonte de energia confiável e eficiente, sem precisar se preocupar com altas contas de energia.`,
        icon: (<MdAttachMoney size={40} color='var(--azul)'/>)
    },
        {txt: `Além disso, oferecemos: 
        -Instalações elétricas em geral e projetos fotovoltaicos personalizados para atender às suas necessidades específicas;
        -Instalação de bombas de poço artesiano, incluindo sistemas solares. `,
        icon: (<RiWaterFlashFill size={40} color='var(--azul)'/>)
    },
    ]
    return(
        
        <div id="copyPage">
            <div className='cardsContainer'>
               {cards.map((card) => (
                <div className='card'>
                    {card.icon}
                    <span className="cardText">{card.txt}</span>
                </div>
               ))} 
            </div>
        </div>
    )
}
export default Copy