import styles from './middleText.module.css'

const MiddleText = ({text}) => {
    return(
        <p className={styles.middleText}>
            {text}
        </p>
    )
}

export default MiddleText