import './reset.css';
import About from './sections/about';
import Content from './sections/content';
import Copy from './sections/copy';
import Footer from './sections/footer';
import Form from './sections/form';
import Galery from './sections/galery';
import Header from './sections/header';
import Home from './sections/home';

function App() {
  return (
    <div className='App'>
      <Header />
      <Home />
      <About />
      <Copy />
      <Form />
      <Galery />
      <Footer />
    </div>
  )
}

export default App;
