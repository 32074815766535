import './styles.css'

const About = () => {
    return(
        <div id='about'>
            <div className='gradient'/>
            <h1 className='title'>QUEM SOMOS</h1>
            <div className='container'>
                <p className='text'>
                    Fundada em julho de 2012, a diCarvalho Solar foi criada para Instalação e manutenção elétrica,  eletroeletrônica, telefonia e rede. 
                    Em 2018 acrescentou em seu range o serviço de projetar, instalar e homologar sistemas fotovoltaico.
                    Com foco em prestar serviços de qualidade, confiança e honestidade a diCarvalho cresce à cada ano sendo vista como uma empresa referência  no ramo de instalação de energia solar. 
                    <span className='detail'>
                    <br/><br/>Quer fazer parte desse grupo de clientes satisfeitos?<br/><br/>
                    Peça um orçamento sem compromisso.</span>
                </p>
                <a className='whatsLink' target='_blank' href='https://whatsa.me/5561998583348/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
                    <div className='btn btnAbout'>Fale Conosco</div>
                </a>
            </div>
        </div>
    )
}

export default About