import whats from '../../imgs/whatsapp.png'

const Header = () => {
    return(
        <header id='header'>
        <a className='whatsLink text' target='_blank' href='https://whatsa.me/5561998583348/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
            <span className='text mainName'>
                diCarvalho Energia Solar
            </span>
        </a>
        <a className='whatsLink' target='_blank' href='https://whatsa.me/5561998583348/?t=Ol%C3%A1!%20Gostaria%20de%20fazer%20um%20or%C3%A7amento.' >
            <span className='text'>
                Contato: 61 9 9858-3348 <img src={whats} width='15'/>
            </span>
        </a>
    </header>
    )
}
export default Header