import styles from './links.module.css'
import wpp from '../../imgs/whatsapp.png'

const Links = () => {
    return(
        <div className={styles.container}>
            <h3 className={styles.title}>Entre em contato: </h3>
            <div className={styles.linksContainer}>
                <a href='https://wa.me/61998583348' target='_blank'><img src={wpp}/></a>
                {/* <a href='/'><img src='/instagram.png'/></a> */}
            </div>
        </div>
    )
}

export default Links