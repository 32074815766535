import styles from './iconText.module.css'

const IconText = ({label, icon}) => {
    return(
        <div className={styles.container}>
            <span className={`material-symbols-outlined`}>{icon}</span>
            <label>{label}</label>
        </div>
    )
}

export default IconText