import styles from './screenContainer.module.css'

const ScreenContainer = ({id, children, direction}) => {
    return(
        <div id={id} className={`${styles.container} ${styles[direction]}`}>
        {children}
        </div>
)
}

export default ScreenContainer