import styles from './textContainer.module.css'

const TextContainer = ({children, color=false}) => {
    return(
        <div className={color? `${styles.textContainer} ${styles.color}`: styles.textContainer}>
            {children}
        </div>
    )

}

export default TextContainer